import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import {
  CircularProgress,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";
import { Box, spacing } from "@mui/system";
import { green, red } from "@mui/material/colors";

import Grid from "@mui/system/Unstable_Grid";


import useAuth from "../../hooks/useAuth";
import DashboardTableInvoice from "../components/TableInvoice";
const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);



function PendingInvoices() {
  let { getApi,postApi,user} = useAuth();

  const { t } = useTranslation();
  const [loadQuery, setLoadQuery] = React.useState(true);

  const [query, setQuery] = React.useState({
    filterdate:3,
    period:3,
    include_mark_paid:true,
    groupby:'day'

  });
  const [loading, setLoading] = React.useState(false);
  const [balanceList, setBalanceList] = React.useState([]);

  
  React.useEffect(() => {
    LoadBalance()

  }, []);


  const LoadBalance = async () => {
    
    let response = await getApi("/balance",{});
    setBalanceList(response)
    setLoading(false)
  };


  return (
    <React.Fragment>
      <Helmet title="Faturas em aberto" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Faturas em aberto.
          </Typography>
          <Typography variant="subtitle1">
            {t("Acompanhe as suas emitidas que não foram pagas.")}
            <span role="img" aria-label="Waving Hand Sign">
             
            </span>
            <img src="/static/img/pending.png" width={20}/>
          </Typography>
        </Grid>

        <Grid item>
        </Grid>
      </Grid>
      
      <Grid container spacing={6}>
        <Grid item xs={12} lg={12}>
          <DashboardTableInvoice status={0} limit={1000} isComponent={true}/>
        </Grid>
      </Grid>

      <Divider my={6} />

     
    </React.Fragment>
  );
}

export default PendingInvoices;

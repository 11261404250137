import React, { useEffect } from "react";
import styled from "@emotion/styled";

import {
  Grid,
  List,
  ListItemText as MuiListItemText,
  ListItemButton as MuiListItemButton,
} from "@mui/material";
import { useLocation } from "react-router";
import useAuth from "../hooks/useAuth";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(0.25)}
    ${(props) => props.theme.spacing(4)};
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItemButton = styled(MuiListItemButton)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

function Footer() {


  let location=window.location.href;

  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  
  
  if(String(location).includes("#addNewClient")){
    console.log( location )
    console.log(window.location)
    window.history.replaceState(null, null, window.location.pathname)
    handleClickOpen();

  
  }


  return (


    <Wrapper>

      <Grid >
        {/* <Grid
          sx={{ display: { xs: "none", md: "block" } }}
          container
          item
          xs={12}
          md={6}
        >
          <List>
            <ListItemButton component="a" href="#">
              <ListItemText primary="Suporte" />
            </ListItemButton>
            <ListItemButton component="a" href="#">
              <ListItemText primary="Privacidade" />
            </ListItemButton>
            <ListItemButton component="a" href="#">
              <ListItemText primary="Termos de Uso" />
            </ListItemButton>
          </List>
        </Grid> */}
    
    <Grid container spacing={0}>
        <Grid
          sx={{ display: { xs: "none", md: "block" } }}
          container
          item
          xs={12}
          md={6}
        >
          <List>
            <ListItemButton component="a" href="/app/contato">
              <ListItemText primary="Suporte" />
            </ListItemButton>
            <ListItemButton target={"_blank"} component="a" href="https://www.cachebank.com.br/politica-de-privacidade/">
              <ListItemText primary="Privacidade" />
            </ListItemButton>
            <ListItemButton target={"_blank"} component="a" href="https://www.cachebank.com.br/contratos/termo-de-uso/atualizado.pdf">
              <ListItemText primary="Termos de Uso" />
            </ListItemButton>
          </List>
          <small style={{color:"#585a63"}}>{window.localStorage.getItem('website_name')} utiliza os serviços da Cachê Bank para processar e gerenciar os seus pagamentos.</small>
        </Grid>
        <Grid container item xs={12} md={6} justifyContent="flex-end">
          <List>
            <ListItemButton>
              <ListItemText primary={`© ${new Date().getFullYear()} - ${window.localStorage.getItem('website_name')} -`} />
            </ListItemButton>
          </List>
        </Grid>
      </Grid>
        
        
      </Grid>
            
         

     




    </Wrapper>
  );
}

export default Footer;

import {
  
  DollarSign,
  Home,
  HelpCircle
} from "react-feather";

const pagesSection = [
  {
    href: "/app",
    icon: Home,
    title: "DashBoard"
  },
  {
    href: "#",
    icon: DollarSign,
    title: "Minhas Faturas",
    active:true,
    children: [
      {
        href: "/app/faturas/pendentes",
        title: "Faturas pendentes",
      },
      {
        href: "/app/faturas/listagem",
        title: "Todas Faturas",
      },
    ]
  }
  ];
  const ContatoSection = [
  {
    href: "/app/contato",
    icon: HelpCircle,
    title: "Fale conosco"
  }
  
];




const navItems = [
  {
    title: "DashBoard",
    pages: pagesSection,
  },
  
  {
    title: "Contato",
    pages: ContatoSection,
  }
];

export default navItems;

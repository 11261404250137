import React from "react";
import styled from "@emotion/styled";
import {  MoreVertical } from "react-feather";
import QRCode from "react-qr-code";

import {
  Card as MuiCard,
  Box,
  CardHeader,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Grid,
  Link,
  Typography,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Modal,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import { useEffect } from "react";

const Card = styled(MuiCard)(spacing);


const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;
const handleFocus = (event) => event.target.select();

const  copyToClipBoard=async (value)=> {
    
  
    await navigator.clipboard.writeText(value);
  }


function DashboardTableInvoice({openOneClickInvoice,closeModalopenOneClickInvoice, invoice}) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
      };

  return (
    <Modal
    open={openOneClickInvoice}
    onClose={closeModalopenOneClickInvoice}
   
  >
    <Box sx={{ ...style, width:  !window.isNativeApp?600:"90%"}}>
    {invoice.status &&
    
    
    
   
   <React.Fragment>
   <center>
   <Typography variant="h6" mb={4}>
          Sua fatura está {invoice.status["name"]}
    </Typography>
   </center>
    {invoice.charges_types &&

    <ShowBoleto invoice={invoice}/>
    }
    
    </React.Fragment>
     }
    </Box>
    </Modal>


  );
}

const ShowBoleto=({invoice})=>{
    
    console.log(invoice);
    const [boleto, setBoleto] = React.useState([]);
    const [qrCodePix, setQrCodePix] = React.useState([]);

    useEffect(() => {
        if(invoice.boleto){
            setBoleto(invoice.boleto);
            setQrCodePix(invoice.pix);
        }
        
      }, [invoice]);
    


    return invoice.boleto?(
        <React.Fragment>
    <hr />
<p><strong>Data de vencimento: {new Date(invoice.due_date).toLocaleDateString()}</strong></p>
<p><strong>Valor: {parseFloat(invoice.amount?invoice.amount:0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></p>


<p>Agora você pode pagar via Pix e ter a confirmação imediata.</p>
<p>Abra o App da sua instituição e escaneie o QrCode Pix abaixo</p>
{qrCodePix.qrcode &&
<center><QRCode value={qrCodePix.qrcode} size={180}  /></center>
}
<center><strong><small>QrCode Pix</small></strong></center>


<p><strong>Pix Copia e Cola</strong></p>
<TextField id="pixcopiacolaId"  onClick={e=> copyToClipBoard(qrCodePix.qrcode)} disabled={false} value={qrCodePix.qrcode} fullWidth ></TextField>


<p></p>
<hr />
<p><strong>Caso prefira pagar via Boleto</strong></p>

<p>Você pode copiar o código de barras abaixo:</p>
<p>
<TextField id="fulldomainId"  onClick={e=> copyToClipBoard(boleto.barcode)} value={boleto.barcode} disabled={false} fullWidth ></TextField>
</p>
<p>Ou você pode baixar o boleto <strong> <a href={invoice.link}>clicando aqui</a></strong></p>
<p></p>
<p></p>
  </React.Fragment>
    )
    :
    (<React.Fragment>

    </React.Fragment>)
 
}


const ShowQrCode=()=>{
    return (
        <DialogContent>
    <hr />
<p><strong>1º Passo</strong></p>
<ShowBoleto/>

<p>Baixe o App <a href=""><strong>Minha Faturas</strong></a>  da loja, <a href="">clicando aqui</a> </p>

<p></p>
<hr />
<p><strong>2º Passo</strong></p>

<p>Copie o código abaixo e cole no App <strong>Minhas Faturas</strong></p>
<p>
<TextField id="fulldomainId"  onClick={e=> copyToClipBoard()} disabled={true} fullWidth ></TextField>
</p>
<p></p>
    </DialogContent>
    )
}

export default DashboardTableInvoice;

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignIn() {
  const navigate = useNavigate();
  const { signIn,configuration } = useAuth();

  let { resetedPassword,email } = useParams();



  return (
    <Formik
      initialValues={{
        username: email?email:"",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string()
          .max(255)
          .required("O login deve ser preenchido"),
        password: Yup.string().max(255).required("A senha deve ser preenchida."),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signIn(values.username, values.password)
            window.location.href="/app";
          
         
        } catch (error) {
          console.log(error);
          const message = error.message["error"] || "Um erro ocorreu. Por favor, tente novamente.";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
         
            {resetedPassword && !errors.submit ?
            (
             <React.Fragment>
               <Alert mt={3} mb={3} severity="success">
               Sua senha foi resetada com sucesso.
              </Alert>
             </React.Fragment>
              ):(
                <Alert mt={3} mb={3} severity="info">
                <React.Fragment>
                Use <strong>
                {
                  configuration &&
                  (
                
                    configuration.auth_identifier==true && configuration.auth_email==true && 
                    (
                      "seu E-mail ou seu CPF/CNPJ"
                    )
                    ||
                    configuration.auth_identifier==true && configuration.auth_email==false && 
                    (
                      "seu CPF/CNPJ"
                    )
                    ||
                    configuration.auth_identifier==false && configuration.auth_email==true && 
                    (
                      "seu E-mail"
                    )
                  )
                }
                </strong> e {" "}
               <strong>sua senha</strong> para logar.
              </React.Fragment>
              
          </Alert>
          )
          }
           
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="text"
            name="username"
            label= {
              configuration &&
              (
                configuration.auth_identifier==true && configuration.auth_email==true && 
                (
                  "Seu E-mail ou seu CPF/CNPJ"
                )
                ||
                configuration.auth_identifier==true && configuration.auth_email==false && 
                (
                  "Seu CPF/CNPJ"
                )
                ||
                configuration.auth_identifier==false && configuration.auth_email==true && 
                (
                  "Seu E-mail"
                )
              )
            }
            value={values.username}
            error={Boolean(touched.username && errors.username)}
            fullWidth
            helperText={touched.username && errors.username}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label="Sua Senha"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
         { /*<FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Lembrar"
          />*/}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Autenticar
          </Button>
          {/* <Button
            component={Link}
            to="/reset-password"
            fullWidth
            color="primary"
          >
            Esqueci a senha
          </Button> */}
        </form>
      )}
    </Formik>
  );
}

export default SignIn;

import React from "react";
import styled from "@emotion/styled";
import { MoreVertical } from "react-feather";
import ShowInvoice from "../../components/modals/showinvoice"
import {
  Card as MuiCard,
  CardHeader,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Grid,
  Link,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";

const Card = styled(MuiCard)(spacing);


const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;



function DashboardTableInvoice(props) {
  const {limit, status, isComponent}= props;
  const [invoiceList, setInvoiceList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);



  let { getApi,postApi,user} = useAuth();

  let title=null;
  if(status==0 && isComponent==false){
    title="Faturas em aberto";
  }
  

  const ColorButtonTransaction =(status)=>{
    let color="primary";
    if(status==0){
      color="boot_warning"
    }else if(status==1){
      color="boot_success"
    }else if(status==2){
      color="boot_info"
    }else if(status==3){
      color="boot_secondary"
    }else if(status==4){
      color="boot_dark"
    }else if(status==5){
      color="boot_danger"
    }else if(status==6){
      color="boot_dark"
    }else if(status==7){
      color="boot_success"
    }else if(status==8){
      color="boot_success"
    }else if(status==9){
      color="boot_success"
    }else if(status==10){
      color="boot_secondary"
    }
  
    return color;
  }


  React.useEffect(() => {
    LoadInvoices(status, limit)

  }, [status]);

  const LoadInvoices = async (invoice_status_id=0, limit=10) => {
    
    let response = await postApi("/invoice/list",{ 
      limit:limit,
      conditions:{
        status:invoice_status_id
      }
    });
    if(response){
      setInvoiceList(response.lista.map(x=> x.invoice))
      setLoading(false)
    }
 
  };

  const [openOneClickInvoice, setOpenOneClickInvoice] = React.useState(false);
  const [selectedInvoice, setSelectedInvoice] = React.useState([]);

  const closeModalopenOneClickInvoice=()=> {
    setOpenOneClickInvoice(false);
  }

  const openInvoice=(value)=>{
    if(value.status["id"]==0){
      setOpenOneClickInvoice(true);
      setSelectedInvoice(value);
    }
   
  }


  return (
  <Card mb={6}>
    {title &&
    <CardHeader
    action={
      <IconButton aria-label="settings" size="large">
        <MoreVertical />
      </IconButton>
    }
    title={title}
  />
    }
    
    <Paper>

  

      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              {!window.isNativeApp &&
                 <React.Fragment>
                  <TableCell align="center">ID</TableCell>
                 <TableCell align="center">Data da Fatura</TableCell>
                 </React.Fragment>
              }
           
              
              <TableCell align="center">Vencimento</TableCell>
              <TableCell align="center">Valor</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceList.map((invoice) => (
              <TableRow key={invoice.id}>
                {!window.isNativeApp &&

                  <React.Fragment>
                     <TableCell sx={{width:'23%'}} align="center">  
                      <Link
                      onClick={e => openInvoice(invoice)}>
                        {invoice.id}
                      </Link>
                    </TableCell>
                    <TableCell align="center">{new Date(invoice.created_at).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'})}</TableCell>

                  </React.Fragment>
                }

              
                <TableCell align="center">{new Date(invoice.due_date).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'})}</TableCell>
                <TableCell align="center">{parseFloat(invoice.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</TableCell>
                <TableCell align="center">
                  <Link 
                    onClick={e => openInvoice(invoice)}>
                    <Button
                          variant="contained"
                          size="small"
                          color={invoice.status?ColorButtonTransaction(invoice.status["id"]):"primary"}>
                        {invoice.status["name"]}
                       
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
      <Grid container justifyContent="center" >
              <Grid item>
              {loading==true &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
              </Grid>
            </Grid> 

      <Grid alignContent={'center'} textAlign="center">
      {loading==false && status==0 &&
        <Link
          href={"/app/faturas/listagem"}>
            
              Mais resultados
        </Link>
      }
      </Grid>
 

    </Paper>
    {invoiceList.length>0 &&
    selectedInvoice  &&
    <ShowInvoice 
    openOneClickInvoice={openOneClickInvoice}
    closeModalopenOneClickInvoice={closeModalopenOneClickInvoice}
    invoice={selectedInvoice}
    />
    
    }
   
  </Card>
  );
}

export default DashboardTableInvoice;

import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Avatar, Button, Card, CardActions, CardContent, CardMedia, Dialog, DialogContent, DialogTitle, Paper, TextField, Typography,View } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";
import useAuth from "../../hooks/useAuth";
import { Box, Container } from "@mui/system";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import QRCode from "react-qr-code";
import appStoreIcon from "../../assets/appstoreicon.png"
import playStoreIcon from "../../assets/playstoreicon.png"
import { Link } from "react-router-dom";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(8)};
    padding-top: ${(props) => props.theme.spacing(0)};
  }
`;

const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`;

function SignIn() {
  
const { configuration } = useAuth();
const [openOneClickNotification, setOpenOneClickNotification] = React.useState(false);

const closeModalNotification=()=> {
  setOpenOneClickNotification(false);

 
}

const  copyToClipBoard=async (value)=> {
  

  await navigator.clipboard.writeText(value);


  // Alert the copied text
  alert("Código copiado, agora é só colar no App ;)");
}
  return (
    <React.Fragment>
     <Container sx={{marginTop:window.isNativeApp?10:20}}>
      
      {
        configuration && 
        (
        configuration.brandmark?
          <center><img src={configuration.brandmark} width="250" height="150" /></center>
          :
          <center>
            <Typography component="h1" variant="h4" align="center" gutterBottom>
              {configuration.website_name}
            </Typography>
            </center>

          )

      }
      
      <Wrapper>
        {configuration &&
         <Helmet title={"Login"} />
        }


        <SignInComponent />
        <br></br>
      <span>Esqueceu a senha? <a href="/reset-password">Clique aqui</a></span>
      
      </Wrapper>
      </Container>
      {configuration &&
       !window.isNativeApp &&
       configuration.allow_app &&
  
      <Card sx={{ maxWidth: 190, border:1 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Baixe o nosso App
        </Typography>
        <small>Aponte a camera no qrcode</small><p></p>
        <center>
          <QRCode size={100}  sx={{fontSize:100}}
          value={"https://api-allstore.cachebank.com.br/downloadapp?configuration_id="+configuration.id}
          />
          </center>
      </CardContent>
      <center><Typography gutterBottom  component="div">
          Já baixou? Abra o App <br></br> E leia o código novamente.
        </Typography></center>
    </Card>
      }

    {configuration.domain &&
       window.isNativeApp &&
       !window.isApp &&
       configuration.allow_app &&
     
      <Card sx={{ border:1, flex:1 }}>
        
        <CardContent>
          <center>
          <Typography gutterBottom variant="h5" component="div">
            Baixe o nosso App
          </Typography>
          
          <a href="#" onClick={e=> setOpenOneClickNotification(true)}>
          {
             window.osSystem=="IOS" &&
              <img src={appStoreIcon} style={{height:"80px"}}/>
          }
           {
             window.osSystem=="Android" &&
              <img src={playStoreIcon} style={{height:"80px"}}/>
          }
          </a>
            
          </center>
        </CardContent>
    </Card>
    }


<Dialog
        open={openOneClickNotification}
        keepMounted
        onClose={closeModalNotification}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle textAlign={'center'}>{"Baixe o nosso App"}</DialogTitle>
        <DialogContent>
        <hr />
<p><strong>1º Passo</strong></p>

<p>Baixe o App <a href=""><strong>Minha Faturas</strong></a>  da loja, <a href="">clicando aqui</a> </p>

<p></p>
<hr />
<p><strong>2º Passo</strong></p>

<p>Copie o código abaixo e cole no App <strong>Minhas Faturas</strong></p>
<p>
  <TextField id="fulldomainId"  onClick={e=> copyToClipBoard(configuration.domain && configuration.domain["fulldomain"])} disabled={false} fullWidth value={configuration.domain && configuration.domain["fulldomain"]}></TextField>
</p>
<p></p>
        </DialogContent>
      </Dialog>



    </React.Fragment>
  );
}

export default SignIn;

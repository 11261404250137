import React from "react";
import { Navigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'

import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AuthGuardBS({ children }) {
  const { isAuthenticated, isInitialized, isInitializedBS, isAuthenticatedBS,businessAccount } = useAuth();
  const location = useLocation();

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/sign-in" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuardBS;

import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function ResetPassword() {
  const navigate = useNavigate();
  const { resetPassword,configuration } = useAuth();

  return (
    <Formik
      initialValues={{
        email: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .max(255)
          .required("O usuário deve ser preenchido."),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          resetPassword(values.email);
         
          navigate("/reset-password/"+values.email+"/0");
        } catch (error) {
          const message = error.message || "Um erro ocorreu. Por favor, tente novamente.";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label={
              configuration &&
              (
                configuration.auth_identifier==true && configuration.auth_email==true && 
                (
                  "Seu E-mail ou seu CPF/CNPJ"
                )
                ||
                configuration.auth_identifier==true && configuration.auth_email==false && 
                (
                  "Seu CPF/CNPJ"
                )
                ||
                configuration.auth_identifier==false && configuration.auth_email==true && 
                (
                  "Seu E-mail"
                )
              )
            }
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Recuperar a senha
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default ResetPassword;
